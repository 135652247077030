import logo from './main.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="img" alt="logo" />
      </header>
    </div>
  );
}

export default App;
